import { defineStore } from 'pinia'
import { calculateWeight, formatCoinValue, zeroIfNan } from '../lib/utils'
import XPTables from '../lib/utils/XPTables'
import { subtractWithBorrow } from '~/lib/utils'
import type {
  InventoryValueMethod,
  LevelingSpeed,
  Item,
  JournalEntry,
  Coinage,
} from '@prisma/client'
import { Character, Inventory, Party } from '../lib/FullTypes'

export * from '../lib/FullTypes'

export const usePartyStore = defineStore('party', {
  state: () =>
    ({
      id: '',
      name: '',
      deleted: false,
      deletedAt: null,
      characters: [],
      coinage: {
        partyId: '',
        platinum: 0,
        gold: 0,
        electrum: 0,
        silver: 0,
        copper: 0,
      },
      inventories: [],
      journal: [],
      knownItems: [],
      npcs: [],
      quests: [],
      showTotalValuesAsGp: false,
      settings: {
        partyId: '',
        showElectrum: false,
        gameSystem: 'PATHFINDER',
        levelSpeed: 'NORMAL',
        autoRemove: false,
        valueCombination: 'ALL',
        useWeight: true,
        inventoryValueMethod: 'SELL_VALUE',
        coinWeight: true,

        modules: {
          partySettingsId: '',
          characters: true,
          inventory: true,
          journal: true,
          npcs: true,
          quests: true,
        },
      },
    } as unknown as Party),
  getters: {
    totalCarryingCapacity(): number {
      let total = 0
      this.characters.forEach((character: Character) => {
        total += calculateWeight(
          character.abilityScores!.strength,
          this.settings!.gameSystem,
          character.acceptedLoad,
          character.size
        )
      })
      return total
    },
    xp(): number {
      let xp = 0
      this.journal.forEach((value: JournalEntry) => {
        const temp = Number.parseFloat(value.xp + '')
        if (!isNaN(temp)) xp += temp
      })
      return xp
    },
    level(): number {
      if (this.settings!.levelSpeed === 'MILESTONE') {
        return this.level
      } else if (this.settings!.gameSystem === 'PATHFINDER') {
        return XPTables.pathfinderXpRequirements[
          translateLevelSpeed(this.settings!.levelSpeed)
        ].findIndex(
          (value, index) =>
            this.xp >= value &&
            this.xp <
              XPTables.pathfinderXpRequirements[
                translateLevelSpeed(this.settings!.levelSpeed)
              ][index + 1]
        )
      } else if (this.settings!.gameSystem === 'DND5E') {
        return XPTables.dndXpRequirements.findIndex(
          (value, index) =>
            this.xp >= value &&
            this.xp <
              XPTables.pathfinderXpRequirements[
                translateLevelSpeed(this.settings!.levelSpeed)
              ][index + 1]
        )
      }
      return NaN
    },
    currentLevelXp(): number {
      const level = this.level
      if (level === -1 || isNaN(level)) {
        return NaN
      }
      if (this.settings!.gameSystem === 'PATHFINDER') {
        return XPTables.pathfinderXpRequirements[
          translateLevelSpeed(this.settings!.levelSpeed)
        ][level]
      } else if (this.settings!.gameSystem === 'DND5E') {
        return XPTables.dndXpRequirements[level]
      }
      return NaN
    },
    nextLevelXp(): number {
      const level = this.level
      if (level === -1 || isNaN(level)) {
        return NaN
      }
      if (this.settings!.gameSystem === 'PATHFINDER') {
        return XPTables.pathfinderXpRequirements[
          translateLevelSpeed(this.settings!.levelSpeed)
        ][level + 1]
      } else if (this.settings!.gameSystem === 'DND5E') {
        return XPTables.dndXpRequirements[level + 1]
      }
      return NaN
    },
    xpPercentage(): number {
      const currentLevelXp = this.currentLevelXp
      return (
        ((this.xp - currentLevelXp) / (this.nextLevelXp - currentLevelXp)) * 100
      )
    },
    xpUntilNextLevel(): number {
      return this.nextLevelXp - this.xp
    },
    inventoryWeight(): number {
      let total = 0
      this.inventories
        .filter((i) => !i.ignoreWeightInTotal && !i.separateMaxWeight)
        .forEach((inventory) => {
          if (!inventory.ignoreWeightInTotal) {
            if (inventory.items) {
              inventory.items.forEach((item: Item) => {
                total +=
                  Number.parseFloat(item.weight + '') *
                  Number.parseFloat(item.quantity + '')
              })
            }
          }
        })
      if (this.settings!.coinWeight) {
        total +=
          (this.coinage!.platinum +
            this.coinage!.gold +
            this.coinage!.silver +
            this.coinage!.copper +
            (this.settings!.showElectrum ? this.coinage!.electrum : 0)) /
          50
      }
      return zeroIfNan(total, true)
    },
    inventoryValue(): number {
      return this.inventories
        .filter((i) => !i.ignoreValueInTotal)
        .reduce((previousValue, inventory) => {
          return (
            previousValue +
            inventory.items.reduce((total, item) => {
              return (
                total +
                item.value *
                  item.quantity *
                  (this.settings?.inventoryValueMethod === 'SELL_VALUE'
                    ? inventory.defaultSellPrice
                    : 1)
              )
            }, 0)
          )
        }, 0)
    },
    inventoryIndividualValues(): { name: string; value: string }[] {
      const values: { name: string; value: string }[] = []
      this.inventories
        .filter((i) => !i.ignoreValueInTotal)
        .forEach((inventory) => {
          const value = calculateInventoryValue(
            inventory,
            this.settings!.inventoryValueMethod
          )
          values.push({
            name: inventory.name,
            value: this.settings?.showTotalValuesAsGp
              ? zeroIfNan(value, true).toString()
              : formatCoinValue(value),
          })
        })
      return values
    },
    inventoryIndividualWeights(): {
      name: string
      weight: number
      maxWeight: number
    }[] {
      const values: { name: string; weight: number; maxWeight: number }[] = []
      this.inventories
        .filter((i) => !i.ignoreWeightInTotal)
        .forEach((inventory) => {
          values.push({
            name: inventory.name,
            weight: zeroIfNan(
              inventory.items.reduce(
                (tot: number, i) => tot + i.weight * i.quantity,
                0
              ),
              true
            ),
            maxWeight: zeroIfNan(
              inventory.separateMaxWeight ? inventory.maxWeight : -1,
              true
            ),
          })
        })
      return values
    },
    coinValue(): number {
      let value = 0
      value += (this.coinage!.platinum ?? 0) * 10
      value += this.coinage!.gold ?? 0
      if (this.settings!.showElectrum)
        value += (this.coinage!.electrum ?? 0) / 2
      value += (this.coinage!.silver ?? 0) / 10
      value += (this.coinage!.copper ?? 0) / 100
      return value
    },
    totalCarriedWeight(): number {
      let total = 0
      this.characters.forEach((character) => {
        if (character.carriedWeight) {
          total += character.carriedWeight
        }
      })
      return total
    },
  },
  actions: {
    subtractCoins(coins: Coinage) {
      try {
        const res = subtractWithBorrow(this.coinage!, coins)
        this.coinage!.platinum = Number.parseInt(res.platinum + '')
        this.coinage!.gold = Number.parseInt(res.gold + '')
        this.coinage!.electrum = Number.parseInt(res.electrum + '')
        this.coinage!.silver = Number.parseInt(res.silver + '')
        this.coinage!.copper = Number.parseInt(res.copper + '')
      } catch (_) {
        // ignore
      }
    },
    addCoins(coins: Coinage) {
      this.coinage!.platinum += Number.parseInt(coins.platinum + '')
      this.coinage!.gold += Number.parseInt(coins.gold + '')
      this.coinage!.electrum += Number.parseInt(coins.electrum + '')
      this.coinage!.silver += Number.parseInt(coins.silver + '')
      this.coinage!.copper += Number.parseInt(coins.copper + '')
    },
  },
})

function calculateInventoryValue(
  inventory: Inventory,
  valueMethod: InventoryValueMethod
): number {
  let value = 0
  if (inventory.items) {
    inventory.items.forEach((item: Item) => {
      if (valueMethod === 'SELL_VALUE') {
        value += item.value * item.quantity * inventory.defaultSellPrice
      } else {
        value += item.value * item.quantity
      }
    })
  }
  return value
}

function translateLevelSpeed(speed: LevelingSpeed): number {
  switch (speed) {
    case 'SLOW':
      return 0
    case 'NORMAL':
      return 1
    case 'FAST':
      return 2
    case 'MILESTONE':
      return -1
  }
}
