/* It's a list of lists of numbers. The first list is the light load, the second is the medium load, and the third is the heavy load. Each list has a number for each strength score. */
import type { CarryLoad, GameSystem, Size } from '@prisma/client'
import { translateCarryLoad, translateSize } from './EnumHelper'

export const PathfinderCarryingCapacities = [
  [
    0, 3, 6, 10, 13, 16, 20, 23, 26, 30, 33, 38, 43, 50, 58, 66, 76, 86, 100,
    116, 133, 153, 173, 200, 233, 266, 306, 346, 400, 466,
  ],
  [
    0, 6, 13, 20, 26, 33, 40, 46, 53, 60, 66, 76, 86, 100, 116, 133, 153, 173,
    200, 233, 266, 306, 346, 400, 466, 533, 613, 693, 800, 933,
  ],
  [
    0, 10, 20, 30, 40, 50, 60, 70, 80, 33, 100, 115, 130, 150, 175, 200, 230,
    260, 300, 350, 400, 460, 520, 600, 700, 800, 920, 1040, 1200, 1400,
  ],
]

/**
 * It takes a strength score, a game system, a carrying load, and a size, and returns the weight that the character can carry
 * @param {number} str - The strength score of the character
 * @param system - The game system you're using.
 * @param acceptedLoad - The type of load the character is carrying. This is a number from 0 to 2, where 0 is light, 1 is medium, and 2 is heavy.
 * @param size - Size.TINY
 * @returns A number
 */
export function calculateWeight(
  str: number,
  system: GameSystem,
  acceptedLoad?: CarryLoad,
  size?: Size
): number {
  if (str === 0 || (!acceptedLoad && acceptedLoad !== 0) || !size) {
    return 0
  }
  if (system === 'PATHFINDER') {
    if (
      str <
      PathfinderCarryingCapacities[translateCarryLoad(acceptedLoad)].length
    ) {
      return (
        PathfinderCarryingCapacities[translateCarryLoad(acceptedLoad)][str] *
        translateSize(size)
      )
    }
    let localStr = str - 20
    let modifier = 1
    while (localStr >= 10) {
      modifier *= 4
      localStr -= 10
    }
    const baseValue = (str % 10) + 20
    return (
      PathfinderCarryingCapacities[translateCarryLoad(acceptedLoad)][
        baseValue
      ] *
      modifier *
      translateSize(size)
    )
  } else {
    let multiplier = 0
    switch (acceptedLoad) {
      case 'LIGHT':
        multiplier = 5
        break
      case 'MEDIUM':
        multiplier = 10
        break
      case 'HEAVY':
        multiplier = 15
        break
    }
    switch (size) {
      case 'TINY':
        return str * multiplier * 0.5
      case 'LARGE':
      case 'HUGE':
      case 'GARGANTUAN':
        return str * multiplier * translateSize(size)
      default:
        return multiplier * str
    }
  }
}
