import type { Coinage } from '@prisma/client'

export function formatCoinValue(value: string | number) {
  if (parseFloat(value + '') === 0) {
    return '0gp'
  }
  const coins = gpToCoins(value)
  let ret = ''
  if (coins.platinum !== 0 && !isNaN(coins.platinum))
    ret += `${coins.platinum}pp`
  if (coins.gold !== 0 && !isNaN(coins.gold)) ret += ` ${coins.gold}gp`
  if (coins.silver !== 0 && !isNaN(coins.silver)) ret += ` ${coins.silver}sp`
  if (coins.copper !== 0 && !isNaN(coins.copper)) ret += ` ${coins.copper}cp`
  return ret.trim()
}

export function gpToCoins(value: string | number, partyId = ''): Coinage {
  value = value.toString()
  value = value.replace(/[^-\d.]/, '')
  if (!value.includes('.')) {
    value += '.00'
  } else {
    const decimalIndex = value.indexOf('.')
    if (value.includes('.', decimalIndex + 1)) {
      const i = value.indexOf('.', decimalIndex + 1)
      value = value.substring(0, i) + value.substring(i).replace('.', '')
    }
    value += '00'
    value = value.substring(0, decimalIndex + 3)
  }
  if (Number.parseFloat(value) === 0) {
    return {
      platinum: 0,
      gold: 0,
      electrum: 0,
      silver: 0,
      copper: 0,
      partyId,
    } as unknown as Coinage
  }
  const copper = Number.parseInt(value.slice(-1))
  const silver = Number.parseInt(value.slice(-2, -1))
  const gold = Number.parseInt(value.slice(-4, -3))
  const platinum = Number.parseInt(value.slice(0, -4))
  return filterNaN({ platinum, gold, electrum: 0, silver, copper } as Coinage)
}

export function optimizeToHighestCoinage(
  coins: Coinage,
  withElectrum = false
): Coinage {
  const ret = filterNaN({ ...coins })
  while (ret.copper >= 10) {
    ret.copper -= 10
    ret.silver += 1
  }
  while (ret.silver >= 10) {
    ret.silver -= 10
    ret.gold += 1
  }
  if (withElectrum) {
    while (ret.silver >= 5) {
      ret.silver -= 5
      ret.electrum += 1
    }
    while (ret.electrum >= 2) {
      ret.electrum -= 2
      ret.gold += 1
    }
  }
  while (ret.gold >= 10) {
    ret.gold -= 10
    ret.platinum += 1
  }
  return filterNaN(ret)
}

export function coinsToGp(coins: Coinage, useElectrum = false): number {
  let value = 0
  const local = filterNaN({ ...coins })
  if (!isNaN(parseInt(local.copper + ''))) {
    value += parseInt(local.copper + '') / 100
  }
  if (!isNaN(parseInt(local.silver + ''))) {
    value += parseInt(local.silver + '') / 10
  }
  if (!isNaN(parseInt(local.gold + ''))) {
    value += parseInt(local.gold + '')
  }
  if (!isNaN(parseInt(local.platinum + ''))) {
    value += parseInt(local.platinum + '') * 10
  }
  if (useElectrum && !isNaN(parseInt(local.electrum + ''))) {
    value += parseInt(local.electrum + '') / 2
  }
  return value
}

export function subtractWithBorrow(
  target: Coinage,
  subtract: Coinage,
  useElectrum = false
): Coinage {
  if (coinsToGp(target, useElectrum) < coinsToGp(subtract, useElectrum)) {
    throw new TypeError('Not enough coins')
  }
  const ret = filterNaN({ ...target })
  while (ret.copper < subtract.copper) {
    ret.copper += 10
    ret.silver -= 1
  }
  if (useElectrum) {
    while (ret.silver < subtract.silver) {
      ret.silver += 5
      ret.electrum -= 1
    }
    while (ret.electrum < subtract.electrum) {
      ret.electrum += 2
      ret.gold -= 1
    }
  } else {
    while (ret.silver < subtract.silver) {
      ret.silver += 10
      ret.gold -= 1
    }
  }
  while (ret.gold < subtract.gold) {
    ret.gold += 10
    ret.platinum -= 1
  }
  ret.copper -= subtract.copper
  ret.silver -= subtract.silver
  if (useElectrum) {
    ret.electrum -= subtract.electrum
  }
  ret.gold -= subtract.gold
  ret.platinum -= subtract.platinum
  while (ret.platinum < 0) {
    if (ret.gold >= 10) {
      ret.platinum += 1
      ret.gold -= 10
    } else if (useElectrum && ret.electrum >= 20) {
      ret.platinum += 1
      ret.electrum -= 20
    } else if (ret.silver >= 100) {
      ret.platinum += 1
      ret.silver -= 100
    } else if (ret.copper >= 1000) {
      ret.platinum += 1
      ret.copper -= 1000
    }
  }
  return filterNaN(ret)
}

export function filterNaN(coins: Coinage): Coinage {
  if (isNaN(parseInt(coins.platinum + ''))) coins.platinum = 0
  if (isNaN(parseInt(coins.gold + ''))) coins.gold = 0
  if (isNaN(parseInt(coins.electrum + ''))) coins.electrum = 0
  if (isNaN(parseInt(coins.silver + ''))) coins.silver = 0
  if (isNaN(parseInt(coins.copper + ''))) coins.copper = 0
  return coins
}

export function zeroIfNan(value: string | number, allowFloat = false): number {
  if (
    allowFloat &&
    (value.toString().includes('.') || value.toString().includes(','))
  ) {
    const val = parseFloat(parseFloat(value.toString()).toFixed(2))
    return isNaN(val) ? 0 : val
  }
  const intVal = parseInt(value + '')
  return isNaN(intVal) ? 0 : intVal
}

export function washCoinage(coins: Coinage) {
  coins.platinum = zeroIfNan(coins.platinum)
  coins.gold = zeroIfNan(coins.gold)
  coins.electrum = zeroIfNan(coins.electrum)
  coins.silver = zeroIfNan(coins.silver)
  coins.copper = zeroIfNan(coins.copper)
  return coins
}
