import type { CarryLoad, Size } from '@prisma/client'

export function translateCarryLoad(carryLoad: CarryLoad): number {
  switch (carryLoad) {
    case 'LIGHT':
      return 0
    case 'MEDIUM':
      return 1
    case 'HEAVY':
      return 2
  }
}

export function translateSize(size: Size): number {
  switch (size) {
    case 'FINE':
      return 1 / 8
    case 'DIMINUTIVE':
      return 1 / 4
    case 'TINY':
      return 1 / 2
    case 'SMALL':
      return 3 / 4
    case 'MEDIUM':
      return 1
    case 'LARGE':
      return 2
    case 'HUGE':
      return 4
    case 'GARGANTUAN':
      return 8
    case 'COLOSSAL':
      return 16
  }
}
